import React from "react"

const Header = () => {
  return (
      <header id="header" className="header">
          <div className="content">
              <strong className="logo">
                  <a  href="https://www.equalitynow.org/">Equality Now</a>
              </strong>
              <strong className="title">TEXT “EQUALITYNOW” TO 707070 TO DONATE <span>US only. Msg & data rates may apply</span></strong>
              <ul className="list-social">
                  <li><a target="_blank" rel="noreferrer"  className="icon twitter" href="https://twitter.com/equalitynow/">twitter</a></li>
                  <li><a target="_blank" rel="noreferrer"  className="icon facebook" href="https://www.facebook.com/equalitynoworg">facebook</a>
                  </li>
                  <li><a target="_blank" rel="noreferrer"  className="icon instagram"
                         href="https://www.instagram.com/equalitynoworg/">instagram</a></li>
                  <li><a target="_blank" rel="noreferrer"  className="icon linkedin"
                         href="https://www.linkedin.com/company/equality-now/">linkedin</a></li>
                  <li><a target="_blank" rel="noreferrer"  className="icon youtube"
                         href="https://www.youtube.com/user/equalitynowyt/">youtube</a></li>
              </ul>
          </div>
      </header>
  )
}

export default Header
