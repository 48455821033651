import React from "react"

const Credits = () => {
  return (
      <div className="credits">
          <div className="content">
              <h2>Credits</h2>
              <ul className="list-credits">
                  <li>
                      <div className="hold-item">
                          <h3>Show Production</h3>
                          <ul className="inner-list">
                              <li>Do Great Projects</li>
                              <li>Cory O’Donnell, Executive Producer</li>
                              <li>Kristin Whalley, Executive Producer</li>
                              <li>Nathaniel Pincus-Roth, Creative Director</li>
                              <li>Jillian Davis, Writer & Director</li>
                              <li>Bex Schwartz, Writer & Director</li>
                              <li>Rebecca Hoobler, Writer</li>
                          </ul>
                      </div>
                      <div className="hold-item">
                          <h3>Press & Talent</h3>
                          <ul className="inner-list">
                              <li>Slate PR</li>
                              <li>Andy Gelb</li>
                              <li>Megan Choate</li>
                              <li>Shawn Purdy</li>
                              <li>Lindsey Brown-McLravy</li>
                              <li>Elyse Weissman</li>
                          </ul>
                      </div>
                      <div className="hold-item">
                          <h3>Sierra Leone</h3>
                          <ul className="inner-list">
                              <li>Written by Katie Cappiello</li>
                              <li>Directed by Katie Cappiello & Charlotte Arnoux</li>
                          </ul>
                      </div>

                  </li>
                  <li>
                      <div className="hold-item">
                          <h3>Livestream Production</h3>
                          <ul className="inner-list">
                              <li>Happily.io</li>
                              <li>Sarah Shewey, Producer</li>
                              <li>Kevin Rabinovich, Producer</li>
                              <li>JC Reifenberg, Engineer</li>
                              <li>Zeeshan Khan, Website</li>
                              <li>Jordan Nerison, Graphics</li>
                              <li>Cynthia Steele, Production Assistant</li>
                          </ul>
                      </div>
                      <div className="hold-item">
                          <h3>Quarantine Highlights</h3>
                          <ul className="inner-list">
                              <li>Written By Jillian Davis</li>
                          </ul>
                      </div>
                      <div className="hold-item">
                          <h3>Zoom School</h3>
                          <ul className="inner-list">
                              <li>Written by Jillian Davis</li>
                          </ul>
                      </div>
                      <div className="hold-item">
                          <h3>Pandemic Cliches</h3>
                          <ul className="inner-list">
                              <li>Written by Bex Schwartz</li>
                          </ul>
                      </div>
                      <div className="hold-item">
                          <h3>Two Girls</h3>
                          <ul className="inner-list">
                              <li>Written by Katie Cappiello</li>
                              <li>Directed by Katie Cappiello & Charlotte Arnoux</li>
                          </ul>
                      </div>
                  </li>
                  <li>
                      <div className="hold-item">
                          <h3>Post Production</h3>
                          <ul className="inner-list">
                              <li>PostScript Creative, LLC</li>
                              <li>Jessica Rothert, Post Production Producer</li>
                              <li>Peter Saroufim, Lead Editor</li>
                              <li>Brad Allen Wilde, Editor</li>
                              <li>Ethan Landry, Assistant Editor</li>
                              <li>Brendan Merrill, Additional Editing</li>
                          </ul>
                      </div>
                      <div className="hold-item">
                          <h3>A Tribute To Ruth Bader Ginsburg</h3>
                          <ul className="inner-list">
                              <li>Edited by Brad Allen Wilde</li>
                              <li>Music by Daphne Willis</li>
                              <li>Performance Footage by Whitney Otte</li>
                          </ul>
                      </div>
                      <div className="hold-item">
                          <h3>Utopia</h3>
                          <ul className="inner-list">
                              <li>Written by Rebecca Hoobler</li>
                          </ul>
                      </div>
                  </li>
              </ul>
          </div>
      </div>
  )
}

export default Credits
