import React from "react"

const Footer = () => {
  return (
      <footer id="footer" className="footer">
          <div className="content">
              <div className="footer-holder">
                  <div className="logo-block">
                      <strong className="logo">
                          <a href="https://www.equalitynow.org/">Equality Now</a>
                      </strong>
                      <span className="copy">Copyright©, 2020 Equality Now</span>
                      <span className="note">Disclaimer note : The views and opinions expressed by invited speakers to this program are their own and do not necessarily replete the official policies or positions of Equality Now.</span>
                  </div>
                  <ul className="right-list">
                      <li><a href="https://www.equalitynow.org/donate/">Support Equality Now</a></li>
                      <li><a rel="noreferrer" href="https://www.equalitynow.org/privacy_policy">Privacy Policy</a></li>
                      <li>EIN #13-3660566</li>
                      <li><a href="https://www.equalitynow.org/faqs">Equality Now FAQs</a></li>
                  </ul>
                  <ul className="list-actions">
                      <li>
                          <h3>Event Support</h3>
                          <a href="mailto:events@equalitynow.org">events@equalitynow.org</a>
                      </li>
                      <li>
                          <h3>Contact us</h3>
                          <a href="mailto:info@equalitynow.org">info@equalitynow.org</a>
                      </li>
                  </ul>

              </div>
          </div>
      </footer>
  )
}

export default Footer
