import React from "react"

const Sponsors = () => {
  return (
      <div className="sponsors">
          <div className="content">
              <h2>Gala Sponsors</h2>
              <ul className="list-sponsors">
                  <li>
                      <h3>Champion of Change</h3>
                      <ul className="list-img">
                          <li className="margin"><img src="./image 13.png" alt="Gucchi"/></li>
                          <li><img src="./image 14.png" alt="Chime"/></li>
                      </ul>
                  </li>
                  <li>
                      <h3>Catalyst of Change</h3>
                      <strong className="sponsor-title">Melony Lewis</strong>
                  </li>
                  <li>
                      <h3>Change Advocate</h3>
                      <strong className="sponsor-title">Karen Lehner</strong>
                      <strong className="sponsor-title">Monica & Phil Rosenthal | Rosenthal Family Foundation</strong>
                      <ul className="list-images">
                          <li><img src="./image 15.png" alt="NBOMax"/></li>
                          <li><img src="./image 18.png" alt="Wonderful"/></li>
                      </ul>
                  </li>
                  <li>
                      <h3>Change Agent</h3>
                      <strong className="sponsor-title">Patricia & Alex Farman-Farmaian <br/> Lisa Henson & Dave Pressler <br/> Jessica Rothenberg-Aalami <br/> Sue Smalley</strong>
                  </li>
                  <li>
                      <h3>Additional Support</h3>
                      <ul className="list-images support">
                          <li><img src="./image 16.png" alt="Merrill"/></li>
                      </ul>
                  </li>
              </ul>
              <ul className="list">
                  <li>
                      <h3>Host Committee</h3>
                      <p>Bonnie Abaunza | Rosanna Arquette | Patricia & Alex Farman-Farmaian | Nancy & Jeffrey Goldstein | Chandra Jessee | Annette Wheeler & Dan Kelly | Simone Lahorgue | Christine Lahti | Karen Lehner | Melony Lewis | Ann MacDougall | Nancy J. Miller | Lisa Henson & Dave Pressler | Paula Ravets | Lynda Resnick | Rosenthal Family Foundation | Jessica Rothenberg-Aalami | Sheetal Sheth | Sue Smalley | Jennifer Allan Soros| Gloria Steinem</p>
                  </li>
                  <li>
                      <h3>Special Thanks</h3>
                      <p>Rachel Decker, CHIME FOR CHANGE <br/> Christine Iacuzzo, Ashley Schaffer, Gucci <br/> Ryan Mazie, Jane Doe Films <br/> Lynn Hendee</p>
                  </li>
              </ul>
          </div>
      </div>
  )
}

export default Sponsors
