import React from "react"
import { useEffect } from 'react'
import { Helmet } from "react-helmet"
import Header from "../components/landingComponents/header.component"
import Intro from "../components/landingComponents/intro.component"
import Featuring from "../components/landingComponents/featuring.component"
import Sponsors from "../components/landingComponents/sponsors.component"
import Credits from "../components/landingComponents/credits.component"
import Footer from "../components/landingComponents/footer.component"
import "../styles/style.scss"



const BlogIndex = () => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://hello.pledgeling.com/assets/widget.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

  return (
     <div>
         <Helmet>
             <link href="https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@300;400;500&display=swap" rel="stylesheet" />

         </Helmet>
         <main id="main">
             <Header/>
             <Intro/>
             <Featuring/>
             <Sponsors/>
             <Credits/>
             <Footer/>
         </main>

     </div>
  )
}

export default BlogIndex


