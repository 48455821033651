import React from "react"

const Featuring = () => {
  return (
      <div className="featuring">
          <div className="content">
              <h2>Featuring</h2>
              <p>Jane Fonda | Jameela Jamil | Aubrey Plaza | Marisa Tomei | Alicia Vikander | Rob Reiner | Christine Lahti | Margaret Atwood | Jess Glynne | Aasif Mandvi | Sheetal Sheth | Karen Robinson | Bob Balaban | V (formerly known as Eve Ensler) | AnnaSophia Robb | Julie Taymor | Carmen Ejogo | Heather McMahan | Amalia Yoo | Linda Perry | Brittany Adebumola | Angélica | Eva Biaudet | Kimberly Guerrero | Monica Sanchez | Lola Blackman | Omar Samra</p>
              <ul className="list-events">
                  <li>
                      <h3>A Tribute to Ruth Bader Ginsburg</h3>
                      <p>Words from Gloria Steinem, spoken by Meryl Streep</p>
                      <p>“Legacy” Performed by Daphne Willis </p>
                  </li>
                  <li>
                      <h3>Honoring the Activists and <br/> Filmmakers of "On the Record"</h3>
                      <p>Introduction by Karamo</p>
                      <p>Attending Participants: Kirby Dick, Drew Dixon, Shanita Hubbard, Kierna Mayo, Dr Joan Morgan, Sheri Sher, Amy Ziering</p>
                  </li>
                  <li>
                      <h3>Third Annual Changemaker Award</h3>
                      <p>Presented by Gucci and CHIME FOR CHANGE</p>
                      <p>Honoree Nadeen Ashraf in conversation with Scarlett Curtis</p>
                  </li>
              </ul>
          </div>
      </div>
  )
}

export default Featuring
