import React from "react"

const Intro = () => {
  return (
      <div className="intro">
          <div className="content">
              <strong className="logo-content">
                  EQUALITYNOW
              </strong>
              <div className="video-box">
                  <iframe title="Live" className="video" src="https://www.youtube.com/embed/cG6uGxGZpXA" frameBorder="0"
                          allow="autoplay; fullscreen" allowFullScreen></iframe>

              </div>
              <div className="text-box">
                  <p>If you’re experiencing issues with the video player, please 1) close all unnecessary tabs, and 2) refresh your browser. The recording of the show will be available shortly after the live show ends. For troubleshooting, please email <a className="link" href="mailto:events@equalitynow.org">events@equalitynow.org</a></p>

              </div>

              <div className="text-wrap">
                  <div className="text">
                      <h1>A Gala For a Just World For Women and Girls</h1>
                      <strong className="date">December 3rd, 2020 5:30pm PST / 8:30pm EST</strong>
                      <p>Each year, the gala raises money to support our team of human rights lawyers who protect and promote the rights of women and girls around the world. This year, it is especially crucial as we see the pandemic put both the rights of women and girls, and our funding  at risk.</p>
                      <p>Join us, and together we can create unshakeable, enduring  equality for women and girls everywhere.</p>
                      <p>To donate directly on Equality Now’s website, please visit <a className="link" href="https://www.equalitynow.org/donate">www.equalitynow.org/donate</a>. <br/>
                          If you’d like to donate by phone, please contact us at <a className="link" href="mailto:events@equalitynow.org">events@equalitynow.org</a>. </p>
                  </div>
                  <div className="widget">
                      <div className="head-text">
                          <strong className="title">TEXT “EQUALITYNOW” TO 707070 TO DONATE</strong>
                          <span className="caption">All one word. US only. Msg & data rates may apply</span>
                      </div>
                      <div className="hold-box">
                          <div className="plg-donate" data-partner-key="21b1e46e78fb77bad290b07c4b379785" data-item-id="96f8f917a7f237702fe67229bbd15cc0"></div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default Intro
